<template>
  <div
    v-if="type === 'text'"
    v-click-outside="reset"
    :class="['edit-input', { 'readonly': readonly }]"
    @keydown.esc="onEscape"
  >
    <div
      v-if="!isEditing"
      class="edit-input__toggle"
      v-on="{
        ...(!toggleOnlyByIcon ? {
          click: show,
        } : {})
      }"
    >
      <b-spinner
        v-if="isLoading"
        class="m-1"
        small
      />
      <masked-input
        v-if="mask && localValue"
        v-model="localValue"
        :mask="mask"
      />
      <div
        v-else
        class="edit-input__toggle__value"
      >
        <slot />
      </div>
      <div
        v-if="!isNotEditable && !hideEditIcon && !readonly && !isMobile"
        class="edit-input__edit-icon"
      >
        <FeatherIcon
          icon="Edit2Icon"
          @click="show"
        />
      </div>
    </div>
    <div
      v-else
      class="edit-input__input"
    >
      <b-input-group
        class="edit-input"
      >
        <b-input-group-prepend
          v-if="isLoading"
        >
          <b-spinner small />
        </b-input-group-prepend>
        <b-form-input
          v-if="!mask"
          ref="input"
          v-model="localValue"
          :placeholder="placeholder"
          :maxlength="maxlength"
          @keydown.enter="onSubmit"
        />
        <masked-input
          v-else
          ref="input"
          v-model="localValue"
          :placeholder="placeholder"
          :mask="mask"
          :guide="guide"
          @keydown.enter="onSubmit"
        />
        <b-input-group-append>
          <feather-icon
            v-if="validLength && validOnlyExists"
            size="22"
            icon="CheckIcon"
            class="text-success cursor-pointer dont-close-input"
            @click="onSubmit"
          />
          <feather-icon
            v-else
            size="22"
            icon="CheckIcon"
            class="text-danger dont-close-input"
          />
          <feather-icon
            size="22"
            icon="XIcon"
            class="text-danger cursor-pointer dont-close-input"
            @click="reset"
          />
        </b-input-group-append>
      </b-input-group>
      <div
        v-if="withSelect && options && options.length"
        class="edit-input__select"
      >
        <template v-if="touched && localValue && !noClientFilterOptions">
          <div
            v-for="option in computedSelectOptions"
            :key="option.value"
            class="edit-input__select-item"
            @click="onItemClick(option)"
          >
            {{ option.label }}
          </div>
        </template>
        <template v-else>
          <div
            v-for="option in options"
            :key="option.value"
            class="edit-input__select-item"
            @click="onItemClick(option)"
          >
            {{ option.label }}
          </div>
        </template>
      </div>
    </div>
  </div>
  <BDropdown
    v-else-if="type === 'dropdown'"
    class="edit-input__dropdown"
    variant="outline-outline-secondary"
    right
    :menu-class="dropdownClass"
    :disabled="readonly"
  >
    <template #button-content>
      <b-spinner
        v-if="isLoading"
        class="mr-1"
        small
      />
      <slot />
      <div
        v-if="!isNotEditable && !hideEditIcon && !readonly"
        class="edit-input__edit-icon"
      >
        <FeatherIcon
          icon="Edit2Icon"
          @click="show"
        />
      </div>
    </template>
    <BDropdownItem
      v-for="(item, idx) in options"
      :key="idx"
      class="edit-input__dropdown-item"
      @click="onItemClick(item)"
    >
      <span class="mr-1">{{ item.label }}</span>
      <FeatherIcon
        v-if="(item.value === localValue) || (item.value === localValue.id)"
        icon="CheckIcon"
      />
    </BDropdownItem>
  </BDropdown>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';

import MaskedInput from 'vue-text-mask';

import {
  computed,
  nextTick,
  ref, toRefs, watch,
} from '@vue/composition-api';
import {useStore} from "@/hooks/useStore";

export default {
  name: 'EditInput',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    BDropdown,
    BDropdownItem,
    MaskedInput,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    model: {
      type: [String, Number, Array, Object, Boolean],
    },
    placeholder: String,
    isLoading: Boolean,
    isNotEditable: Boolean,
    hideEditIcon: Boolean,
    allowCustomValue: {
      type: Boolean,
      default: true,
    },
    toggleOnlyByIcon: {
      type: Boolean,
      default: true,
    },
    withSelect: Boolean,
    options: Array,
    noClientFilterOptions: Boolean,
    maxlength: Number,
    length: Number,
    mask: {
      type: [Array, Function],
      default: null,
    },
    guide: Boolean,
    isPhone: Boolean,
    onlyExists: Boolean,
    readonly: Boolean,
    dropdownClass: String,
  },
  setup(props, { emit }) {
    const {
      options, allowCustomValue, length, isPhone, onlyExists, readonly,
    } = toRefs(props);
    const input = ref(null);

    const touched = ref(false);
    const localValue = ref(null);
    const isSubmit = ref(false);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    const localValueJsonSetter = (value) => {
      try {
        localValue.value = JSON.parse(JSON.stringify(value || props.model || ''));
          if (typeof localValue.value === 'number') {
              localValue.value = String(localValue.value);
          }
      } catch (error) {
        console.error(error);
      }
    };
    localValueJsonSetter();

    watch(computed(() => props.model), (newModel) => {
      localValueJsonSetter(newModel);
    }, {
      immediate: true,
    });
    const computedSelectOptions = computed(
      () => (localValue.value
        // eslint-disable-next-line max-len
        ? options.value?.filter((option) => option.label.toLowerCase().includes((localValue.value?.name || localValue.value)?.toLowerCase()))
        : options.value),
    );
    const computedSelectOptionExists = computed(() => (localValue.value
    // eslint-disable-next-line max-len
      ? options.value?.filter((option) => option.label === localValue.value)
      : options.value));

    watch(localValue, (val) => {
      touched.value = isSubmit.value === false;
      isSubmit.value = false;
      emit('update:localValue', val);
    });

    const isEditing = ref(false);
    watch(isEditing, (val) => {
      if (!val) return;
      localValueJsonSetter();
    });
    const show = () => {
      if (readonly.value) return;
      isEditing.value = true;
      emit('edit');
      nextTick(() => {
        input.value.$el ? input.value.$el.focus() : input.value.focus();
      });
    };
    const hide = () => {
      isEditing.value = false;
      emit('blur');
    };
    const reset = () => {
      localValueJsonSetter();
      hide();
    };
    const submit = () => {
      if (readonly.value) return;
      isSubmit.value = true;
      emit('input', localValue.value);
      localValueJsonSetter();
      hide();
    };

    const validLength = computed(() => {
      if (!length.value) return true;
      if (
        localValue.value
        && isPhone.value && localValue.value.length === length.value
        && localValue.value[localValue.value.length - 1] !== '_'
      ) return true;

      if (!localValue.value || localValue[0] === '_') return true;

      return false;
    });

    const validOnlyExists = computed(() => {
      if (onlyExists.value) {
        if (localValue.value && computedSelectOptionExists.value?.length > 0) return true;
        return false;
      }
      return true;
    });

    const onSubmit = () => {
      if (!validLength.value) return;
      if (!allowCustomValue.value) {
        hide();
        return;
      }
      isSubmit.value = true;
      submit();
    };

    const onItemClick = async (item) => {
      isSubmit.value = true;
      localValue.value = item.value;
      submit();
    };

    const onEscape = () => {
      reset();
    };

    return {
      input,
      localValue,
      computedSelectOptions,
      isEditing,
      show,
      reset,
      submit,
      onSubmit,
      onItemClick,
      onEscape,
      validLength,
      touched,
      validOnlyExists,
      isMobile,
    };
  },
};
</script>

<style lang="scss">
  @import "~@/assets/scss/variables.scss";

  .edit-input.readonly {
    cursor: text
  }
  .edit-input {
    // max-width: 250px;
    width: 100%;
    min-width: 0;
    cursor: pointer;
    input {
      min-height: 30px !important;
      outline: none;
      border: none;
      background-color: transparent;
    }
    &.input-group {
      box-shadow: none !important;
    }
    .form-control {
      border: none;
      box-shadow: none;
      height: auto;
      color: $primary;
      font-weight: $font-weight-bold;
      background-color: transparent;
      padding-left: 0;
      height: 30px;
      &:active, &:focus {
        box-shadow: none !important;
      }
    }
    .input-group-prepend, .input-group-append {
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__toggle {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 58px;
      min-height: 30px;
      @media (max-width: 600px) {
        padding-right: 0;
      }
      &__value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & .badge {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__edit-icon {
      position: absolute;
      right: 0;
      display: none;
      cursor: pointer;
    }
    &__dropdown {
      position: relative;
      margin: 5px 0;

      .dropdown-toggle {
        padding-right: 26px;
        display: flex;
        align-items: center;
      }
      &:hover {
        .edit-input__edit-icon {
          display: block;
        }
      }
      .dropdown-toggle::after {
        display: none !important;
      }

      button {
        padding: 0;
      }

      .dropdown-menu {
        padding: 0;
      }

      &-item {
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    &__input {
      position: relative;
    }
    &__select {
      position: absolute;
      max-height: 152px;
      left: 0;
      top: 100%;
      z-index: 3;
      background: #fff;
      border-radius: 0 0 5px 5px;
      border: 1px solid $gray-200;
      border-top: none;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        max-width: 100%;
        overflow: hidden;
        &:hover {
          background: rgba(115, 103, 240, 0.13);
        }
      }
    }
    &:hover {
      .edit-input__edit-icon {
        display: block;
      }
    }
  }

  body {
    &.dark-layout {
      .edit-input__select {
        background-color: #161d31;
        border-color: #161d31;
      }
      .edit-input__toggle__value {
        color: #d0d2d6
      }
      .edit-input__toggle, .edit-input__toggle input, .edit-input__input input {
        color: #d0d2d6
      }
    }
  }
</style>
