<template>
  <b-card
    class="facility-props"
    no-body
  >
    <AppCollapse>
      <AppCollapseItem
        v-if="!noContacts && item.id"
        title=""
        :is-visible.sync="isContactsDropdownVisible"
        class="collapse-no-body"
        no-body
      >
        <template
          #header
        >
          <div class="d-flex w-100 align-items-center justify-content-between pl-1 pt-1 pb-1 pr-1">
            <h4 class="m-0">
              Контакты
            </h4>
            <FeatherIcon
              v-if="$store.getters['user/permission']('facilities:update')"
              id="addClientIcon"
              icon="PlusIcon"
              size="24"
              @click.stop="addContact"
            />
          </div>
        </template>
        <template>
          <Contacts
            v-if="item.facility_id"
            :contacts="item.facility.contacts"
            entity-name="facility"
            :entity-id="item.facility.id"
            :add-contact.sync="newContactVisible"
            @needToUpdate="$emit('needToUpdate')"
          />
          <div
            v-else
            class="text-muted"
            style="padding: 0 28px 28px 28px"
          >
            Для добавления контактов привяжите к заявке объект
          </div>
        </template>
      </AppCollapseItem>

      <AddressCollapse
        v-if="item.facility_id"
        :facility-id="item.facility_id"
        :facility="item.facility"
        label="Объект"
        facility-selector
        :value="item.facility.address"
        :lat="item.facility.lat"
        :lng="item.facility.lon"
        readonly
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
        @needToUpdate="$emit('needToUpdate')"
      >
        <FacilitySelect
          v-if="$store.getters['user/permission']('requests:update')"
          :selected="item.facility"
          :disabled="isRequestStatusCompleted"
          @updateField="$emit('updateField', $event)"
          @updateFields="$emit('updateFields', $event)"
        />
        <template v-else-if="item.facility">
          {{ item.facility.name }}
        </template>
      </AddressCollapse>

      <div
        v-else
        style="padding: 21px"
      >
        <h4 class="mb-2">
          Объект
        </h4>
        <FacilitySelect
          v-if="$store.getters['user/permission']('requests:update')"
          :selected="item.facility"
          :return-object="!item.id"
          :disabled="isRequestStatusCompleted"
          @updateField="$emit('updateField', $event)"
          @updateFields="$emit('updateFields', $event)"
        />
      </div>
    </AppCollapse>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BCard } from 'bootstrap-vue';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import AddressCollapse from '@/components/address/AddressCollapse.vue';
import FacilitySelect from '@/components/fields/facilitySelect/index.vue';
import Contacts from '../contacts/Contacts.vue';

export default {
  components: {
    BCard,
    AppCollapse,
    AppCollapseItem,
    Contacts,
    AddressCollapse,
    FacilitySelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    noContacts: Boolean,
    isRequestStatusCompleted: Boolean,
  },
  setup() {
    const isContactsDropdownVisible = ref(true);
    const newContactVisible = ref(false);
    const addContact = () => {
      newContactVisible.value = true;
      isContactsDropdownVisible.value = true;
    };

    return {
      isContactsDropdownVisible,
      newContactVisible,
      addContact,
    };
  },
};
</script>
