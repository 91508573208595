<template>
  <AppCollapseItem
    title=""
    :is-visible="isOpened"
    class="collapse-no-body"
    no-body
  >
    <template #header>
      <div class="d-flex w-100 align-items-center justify-content-between pl-1 pt-1 pb-1 pr-1">
        <h4 class="m-0">
          {{ label || 'Адрес' }}
        </h4>
        <BButton
          v-if="lat && lng"
          class="p-50"
          variant="primary"
          @click.stop="getRoute"
        >
          <FeatherIcon
            icon="SendIcon"
            size="24"
          />
        </BButton>
      </div>
    </template>
    <div style="padding: 0 28px 0 28px">
      <slot />
    </div>
    <AddressField
      :value="value"
      :lat="lat"
      :lng="lng"
      :readonly="readonly"
      :facility-id="facilityId"
      :facility="facility"
      @updateField="$emit('updateField', $event)"
      @updateFields="$emit('updateFields', $event)"
      @needToUpdate="$emit('needToUpdate')"
    />
  </AppCollapseItem>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import { BButton } from 'bootstrap-vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import AddressField from './AddressField.vue';

export default {
  components: {
    AppCollapseItem,
    AddressField,
    BButton,
  },
  props: {
    facilityLink: String,
      facility: [Object, null],
    facilityId: [String, Number],
    label: String,
    value: {
      type: String,
      default: '',
    },
    lat: {
      type: [String, Number],
      default: null,
    },
    lng: {
      type: [String, Number],
      default: null,
    },
    readonly: Boolean,
  },
  setup(props) {
    const isOpened = ref(true);
    const { lat, lng } = toRefs(props);
    const getRoute = () => {
      const link = `https://yandex.ru/maps/?origin=jsapi21&ll=${lng.value},${lat.value}&z=15&l=map&from=api-maps&rtt=auto&rtext=~${lat.value},${lng.value}`;
      window.open(link, '_blank');
    };

    return {
      isOpened,
      getRoute,
    };
  },
};
</script>
