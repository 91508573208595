<template>
  <div class="address">
    <div
class="address-field"
data-app
>
        <EditInput
        :model="value"
        :toggle-only-by-icon="false"
        :placeholder="t('dictionary.field.address.placeholder')"
        :options="addresses"
        no-client-filter-options
        :with-select="true"
        :readonly="readonly"
        @input="updateAddress"
        @update:localValue="onUpdate"
        @edit="onUpdate"
      >
        {{ value || t('dictionary.field.address.placeholder') }}
      </EditInput>
        <div class="address-field-copy">
            <Copy :value="value" />
        </div>
    </div>
    <div
      class="address-map"
    >
        <b-card
                v-if="findedMarker !== null"
                class="address-new"
                no-body
        >
            <h4>{{ t('facilities.change-address') }}</h4>
            <p class="address-new__name">
                <strong>{{ addressValue }}</strong>
            </p>
            <div class="address-new__buttons">
                <BButton
                        variant="primary"
                        @click="updateAddress(addressValue)"
                >
                    Сохранить
                </BButton>
                <BButton
                        variant="outline-primary"
                        @click="findedMarker = null"
                >
                    Отменить
                </BButton>
            </div>
        </b-card>
      <Map
        :recenter-to="latLng"
        :markers="markers"
        :edit-mode="!readonly"
        :with-servicemans="true"
        :zoom="15"
        @newMarker="geolocate"
      />
    </div>
  </div>
</template>

<script>
import {
  toRefs, computed, ref, provide,
} from '@vue/composition-api';
import Map from '@/components/mapView/Map.vue';
import EditInput from '@/components/editInput/EditInput.vue';
import { useI18n } from '@/hooks/useI18n';
import Copy from '@/components/Copy.vue';
import { useStore } from '@/hooks/useStore';
import {
    BButton,
    BCard,
} from 'bootstrap-vue';
import FacilityMapBallonCard from "@/components/facilities/FacilityMapBallonCard.vue";

export default {
  components: {
      BButton,
    Map,
    EditInput,
    Copy,
      BCard,
  },
  props: {
      facility: [Object, null],
    facilityId: [String, Number],
    value: {
      type: String,
      default: '',
    },
    lat: {
      type: [String, Number],
      default: null,
    },
    lng: {
      type: [String, Number],
      default: null,
    },
    readonly: Boolean,
  },
    setup(props, {emit}) {
        const {t} = useI18n();
        const {
            lat, lng, value, facilityId, readonly, facility,
        } = toRefs(props);

        const addressValue = ref(null);
        const store = useStore();

        const latLng = computed(() => [lat.value, lng.value]);

        const addresses = ref([]);

        const getAddresses = async (query) => {
            try {
                const res = await store.dispatch('yandex/getAddresses', {query: query || value.value});
                addresses.value = res.map(({ value, data }) => ({
                    label: value,
                    value,
                    data,
                }));
            } catch (err) {
                console.error(err);
            }
        };

        let searchDebounceTimer = null;
        const findedMarker = ref(null);

        const updateAddress = async (value) => {
            const name = value.name || value;
            if (!value.lat && addresses.value) {
                let findedAddress = addresses.value.find((i) => i.value === name);
                if (!findedAddress && findedMarker.value) {
                    findedAddress = {
                        label: value,
                        value,
                        data: {
                            geo_lat: findedMarker.value.latLng[0],
                            geo_lon: findedMarker.value.latLng[1],
                        }
                    };
                }
                if (findedAddress) {
                    if (!facilityId.value) {
                        emit('updateFields', {
                            model: {
                                address: name,
                                lat: findedAddress.data.geo_lat,
                                lon: findedAddress.data.geo_lon,
                            },
                        });
                        findedMarker.value = null;
                        return;
                    }

                    await store.dispatch('facilities/updateFacility', {
                        facilityId: facilityId.value,
                        model: {
                            address: name,
                            lat: findedAddress.data.geo_lat,
                            lon: findedAddress.data.geo_lon,
                        },
                    });
                    emit('needToUpdate');
                }
            } else {
                if (!facilityId.value) {
                    emit('updateFields', {
                        model: {
                            address: name,
                            lat: value.lat,
                            lon: value.lon,
                        },
                    });
                    findedMarker.value = null;
                    return;
                }
                await store.dispatch('facilities/updateFacility', {
                    facilityId: facilityId.value,
                    model: {
                        address: name,
                        lat: value.lat,
                        lon: value.lon,
                    },
                });
                emit('needToUpdate');
            }
            findedMarker.value = null;
        };
        provide('updateAddress', updateAddress);

        const onUpdate = (value) => {
            clearTimeout(searchDebounceTimer);
            searchDebounceTimer = setTimeout(() => {
                getAddresses(value);
                searchDebounceTimer = null;
            }, 500);
        };

        const initialMarker = computed(() => {
            if (lat.value && lng.value) {
                return {
                    id: Date.now(),
                    latLng: [lat.value, lng.value],
                    name: value.value,
                    data: facility.value,
                    component: FacilityMapBallonCard,
                    type: 'initial',
                };
            }

            return null;
        });

        const markers = computed(() => [initialMarker.value, findedMarker.value].filter((i) => i));
        const geolocate = async (coords) => {
            if (readonly.value) return;
            try {
                const res = await store.dispatch('yandex/geolocate', { coords });
                const findedAddress = res[0];
                if (findedAddress) {
                    findedMarker.value = {
                        id: Date.now(),
                        latLng: coords,
                        name: findedAddress.value,
                        data: facility.value,
                        component: FacilityMapBallonCard,
                        type: 'finded',
                    };
                    addressValue.value = findedAddress.value;
                }
            } catch (err) {
                console.error(err);
            }
        };

        return {
            t,
            latLng,
            addresses,
            getAddresses,
            updateAddress,
            onUpdate,
            geolocate,
            markers,

            addressValue,
            findedMarker,
        };
    },
};
</script>

<style lang="sass">
.address
  padding: 0 28px 28px 28px
  &__edit-button
    margin-left: 15px
  &-field
    display: flex
    align-items: center
    margin-bottom: 14px
    gap: 15px
    &-copy
      width: 18px
      margin-left: 4px
  &-map
    position: relative
    height: 500px
  &-new
    z-index: 2
    position: absolute
    bottom: 15px
    left: 15px
    right: 15px
    padding: 15px
    border: 1px solid #eee !important
    &__name
      color: #7367f0
    &__buttons
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-start
      gap: 8px
</style>
