<template>
  <div class="contacts">
    <div
      v-if="!contacts.length"
      class="contacts-empty"
    >
      {{ t('contact.empty') }}
    </div>

    <template v-for="contact in contacts">
      <hr :key="contact.id + 'hr'">
      <ContactItem
        :key="contact.id"
        :item="contact"
        :entity-name="entityName"
        @updateContact="updateContact"
        @removeContact="removeContact"
      />
    </template>
    <div v-if="newContactVisible">
      <hr>
      <ContactItem
        :entity-name="entityName"
        @updateContact="updateContact"
        @createContact="createContact"
        @cancelNew="cancelNew"
      />
    </div>
  </div>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';
import errorsHelper from '@/utils/errorsHelper';
import ContactItem from './ContactItem.vue';

export default {
  components: { ContactItem },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    entityName: {
      type: String,
      default: null,
    },
    entityId: {
      type: [Number, String],
      default: null,
    },
    addContact: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { entityName, entityId, addContact } = toRefs(props);
    const { dangerToast } = useToast();
    const { t } = useI18n();
    const { getError } = errorsHelper();

    const newContactVisible = ref(false);
    watch(addContact, (value) => {
      newContactVisible.value = value;
    });

    const cancelNew = () => {
      newContactVisible.value = false;
      emit('update:addContact', false);
    };

    const createContact = async (contact) => {
      const response = await store.dispatch('contacts/add', {
        entityName: entityName.value,
        entityId: entityId.value,
        contact,
      });
      if (response.result) {
        emit('needToUpdate');
        cancelNew();
      } else {
        dangerToast(t('errors.something-went-wrong.text'), getError(response.data.errors));
      }
    };

    const updateContact = async (contact) => {
      const response = await store.dispatch('contacts/update', { contact });
      if (response.result) {
        // emit('needToUpdate');
      } else {
        dangerToast(t('errors.something-went-wrong.text'), getError(response.data.errors));
      }
    };

    const removeContact = async (contact) => {
      const response = await store.dispatch('contacts/remove', {
        contact,
        entityName: entityName.value,
        entityId: entityId.value,
      });
      if (response.result) {
        emit('needToUpdate');
      }
    };

    return {
      updateContact,
      removeContact,
      createContact,
      cancelNew,
      newContactVisible,
      t,
    };
  },
};
</script>

<style lang="sass">
.contacts
  margin-bottom: 14px
  &-empty
    margin-left: 28px
    margin-right: 28px
  .contact
    margin-left: 24px
    margin-right: 24px
</style>
