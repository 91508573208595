<template>
  <div class="facilitySelect d-flex gap-2">
    <div class="facilitySelect-name">
      <BLink
        v-if="selected"
        :to="`/facility/${selected.id}`"
        target="_blank"
      >
        {{ selected.name }}
      </BLink>
      <div
        v-else
        v-b-modal.facilitySelectModal
      >
        Объект не прикреплён
      </div>
    </div>

    <div
      v-if="!disabled"
      v-b-modal.facilitySelectModal
    >
      <feather-icon icon="Edit2Icon" />
    </div>

    <b-modal
      id="facilitySelectModal"
      ref="facilitySelectModal"
      size="xl"
      hide-footer
      title="Выбор объекта"
      body-class="m-0 p-0"
      centered
      no-body
    >
      <FacilitiesList
        readonly
        selectable
        :selected="selected ? selected.id : null"
        get-action="facilities/getFacilitiesForSelector"
        one-page
        target="_blank"
        no-cache
        @selected="update"
      />
      <div class="d-flex gap-2 m-2">
        <b-button @click="cancel">
          Отменить
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BLink } from 'bootstrap-vue';
import { ref, toRefs } from '@vue/composition-api';
import FacilitiesList from '@/views/facilities/list/FacilitiesList.vue';

export default {
  components: {
    BModal,
    BButton,
    FacilitiesList,
    BLink,
  },
  props: {
    selected: Object,
    returnObject: Boolean,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const { returnObject } = toRefs(props);
    const facilitySelectModal = ref(null);
    const cancel = () => {
      facilitySelectModal.value.hide();
    };
    const update = (facility) => {
      if (returnObject.value) {
        emit('updateFields', { model: { facility, facility_id: facility.id } });
      } else {
        emit('updateField', { field: 'facility_id', value: facility.id, update: true });
      }
      facilitySelectModal.value.hide();
    };

    return {
      facilitySelectModal,
      cancel,
      update,
    };
  },
};
</script>

<style lang="sass">
.facilitySelect
  &-name
    color: #7367F0
    text-decoration: underline
#facilitySelectModal
  .facilitiesList
    max-height: calc(100vh - 136px)
    overflow-y: auto
    overflow-x: hidden
    .map
      height: calc(100vh - 194px)
      top: 66px
</style>
