<template>
  <div>
    <input
      ref="input"
      style="height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        position: absolute;
        pointer-events: none;"
      :value="value"
    >
    <feather-icon
      class="copy-icon"
      icon="CopyIcon"
      size="18"
      @click="copy"
    />
  </div>
</template>

<script>
import { toRefs, ref } from '@vue/composition-api';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { successToast } = useToast();
    const { t } = useI18n();

    const { value } = toRefs(props);
    const input = ref(null);

    const fallback = (text) => {
      const area = document.createElement('textarea');
      area.value = text;
      area.contentEditable = true;
      area.style.position = 'fixed'; // avoid scrolling to bottom

      document.body.appendChild(area);
      area.focus();
      area.select();

      const res = document.execCommand('copy');

      area.remove();
      return res;
    };

    const copyToClipboard = (text) => (navigator.clipboard !== undefined
      ? navigator.clipboard.writeText(text)
      : new Promise((resolve, reject) => {
        const res = fallback(text);
        if (res) {
          resolve(true);
        } else {
          reject(res);
        }
      }));

    const copy = () => {
      copyToClipboard(value.value).then(() => {
        successToast(t('dictionary.copy.success'), value.value);
      });
    };

    return {
      input,
      copy,
    };
  },
};
</script>

<style lang="sass">
.copy-icon
  cursor: pointer
</style>
