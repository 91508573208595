<template>
  <div class="contact">
    <div class="contact-main">
      <div class="contact-body">
        <div class="contact-field">
          <FeatherIcon
            icon="UserIcon"
            class="contact-field__icon"
          />
          <EditInput
            :model="localItem.name"
            :placeholder="t('contact.field.name.placeholder')"
            :toggle-only-by-icon="false"
            :maxlength="34"
            :readonly="!$store.getters['user/permission'](permissionName)"
            @input="updateField('name', $event)"
          >
            <span class="contact-field__value">
              {{ localItem.name || t('contact.field.name.empty') }}
            </span>
          </EditInput>
        </div>

        <div class="contact-field">
          <FeatherIcon
            icon="PhoneIcon"
            class="contact-field__icon"
          />
          <EditInput
            :model="localItem.phone"
            :placeholder="t('contact.field.phone.placeholder')"
            :toggle-only-by-icon="false"
            :maxlength="34"
            :readonly="!$store.getters['user/permission'](permissionName)"
            @input="updateField('phone', $event)"
          >
            <span class="contact-field__value">
              {{ localItem.phone || t('contact.field.phone.empty') }}
            </span>
          </EditInput>
        </div>

        <div class="contact-field">
          <FeatherIcon
            icon="BriefcaseIcon"
            class="contact-field__icon"
          />
          <EditInput
            :model="localItem.position"
            :toggle-only-by-icon="false"
            :maxlength="34"
            :readonly="!$store.getters['user/permission'](permissionName)"
            @input="updateField('position', $event)"
          >
            <b-badge
              variant="light-primary"
              style="vertical-align: middle;"
            >
              <span class="contact-field__value">
                {{ localItem.position || t('contact.field.position.empty') }}
              </span>
            </b-badge>
          </EditInput>
        </div>

        <div class="contact-field">
          <FeatherIcon
            icon="MailIcon"
            class="contact-field__icon"
          />
          <EditInput
            :model="localItem.email"
            :placeholder="t('contact.field.email.placeholder')"
            :toggle-only-by-icon="false"
            :maxlength="34"
            :readonly="!$store.getters['user/permission'](permissionName)"
            @input="updateField('email', $event)"
          >
            <span class="contact-field__value">
              {{ localItem.email || t('contact.field.email.empty') }}
            </span>
          </EditInput>
        </div>
      </div>
      <div
        v-if="localItem.id && $store.getters['user/permission'](permissionName)"
        class="contact-actions"
      >
        <BDropdown
          right
          class="p-0"
          variant="link"
        >
          <template #button-content>
            <feather-icon
              class="text-body"
              icon="MoreVerticalIcon"
              size="18"
            />
          </template>
          <BDropdownItem @click="$emit('removeContact', localItem)">
            <FeatherIcon
              icon="Trash2Icon"
              class="mr-1 text-danger"
            />
            <span class="text-danger">
              Удалить
            </span>
          </BDropdownItem>
        </BDropdown>
      </div>
    </div>
    <div
      v-if="!localItem.id && $store.getters['user/permission'](permissionName)"
      class="contact-footer mt-1"
    >
      <BButton
        variant="primary"
        @click="createNew"
      >
        {{ t('dictionary.create') }}
      </BButton>
      <BButton @click="cancelNew">
        {{ t('dictionary.cancel') }}
      </BButton>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, computed } from '@vue/composition-api';
import {
  BBadge, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import getPermissionName from '@/utils/getPermissionName';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: {
    EditInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    entityName: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { item, entityName } = toRefs(props);
    const localItem = ref(item.value);

    const permissionName = computed(() => getPermissionName(entityName.value, 'update'));

    const updateField = (field, value) => {
      const contact = { ...localItem.value };
      contact[field] = value;

      localItem.value = contact;
      if (contact.id) emit('updateContact', contact);
    };

    const createNew = () => {
      emit('createContact', localItem.value);
    };
    const cancelNew = () => {
      emit('cancelNew');
    };

    return {
      t,
      updateField,
      localItem,
      createNew,
      cancelNew,
      permissionName,
    };
  },
};
</script>

<style lang="sass">
.contact
  &-main
    display: flex
  &-footer
    display: flex
    gap: 8px
    margin-top: 8px
  &-actions
    flex: 0
    & button
      padding: 0
    & .dropdown-toggle:after
      display: none
  &-body
    display: flex
    flex: 1
    align-items: center
    flex-wrap: wrap
    column-gap: 8px
    @media (max-width: 1199px)
      width: 100%
  &-field
    display: flex
    align-items: center
    flex: 50%
    max-width: calc(50% - 8px)
    overflow: hidden
    text-overflow: ellipsis
    @media (max-width: 1199px)
      max-width: 100%
      flex: 100%
    &__icon
      min-width: 14px
      margin-right: 10px
</style>
